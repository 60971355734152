import * as React from 'react'
import { motion, MotionValue, useTransform } from 'framer-motion'
import { useProgress } from './Scrollable'

interface WrapperProps {
  parentHeight: number,
  children?: JSX.Element
}

export const VerticalWrapper = (props: WrapperProps) => {

  const { parentHeight } = props
  const el = React.useRef<HTMLInputElement>(null)
  const [threshold, setThreshold] = React.useState(0)
  const { progress } = useProgress()

  React.useEffect(() => {
    if (el.current) {

      const { offsetTop, clientHeight } = el.current

      setThreshold((offsetTop + clientHeight) / parentHeight)

    }
  }, [parentHeight])

  const style = {
    y: useTransform(progress, v => {

      if (v > threshold) {
        return parentHeight
      }

      return 0

    })
  }

  return (
    <motion.div ref={el} style={style}>
      {props.children}
    </motion.div>
  )

}
